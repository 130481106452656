module.exports = {
    VERIFY_USER: 'VERIFY_USER',
    USER_CONNECTED: 'USER_CONNECTED',
    USER_DISCONNECTED: 'USER_DISCONNECTED',
    IS_TYPING: 'IS_TYPING',
    USER_TYPING: 'USER_TYPING',
    SEND_MESSAGE: 'SEND_MESSAGE',
    RECEIVE_MESSAGE: 'RECEIVE_MESSAGE',
    SEND_ALL_ONLINE_USERS: 'SEND_ALL_ONLINE_USERS',
    SEND_REQUEST: 'SEND_REQUEST',
    RECEIVE_REQUEST: 'RECEIVE_REQUEST',

}